<template>
  <div class="page-content">
    <div class="header-box mb-24">
      <div class="left">
        <!-- <span>公司名称：</span>
        <el-select
          class="mr-20"
          v-model="queryParams.EnterpriseId"
          clearable
          filterable
        >
        </el-select> -->
        <span>岗位：</span>
        <el-select
          class="mr-20"
          v-model="queryParams.JobId"
          clearable
          filterable
        >
          <el-option
            v-for="item in jobList"
            :key="item.Id"
            :label="item.Name"
            :value="item.Id"
          ></el-option>
        </el-select>
        <el-button type="primary" @click="initialize">搜索</el-button>
      </div>
    </div>
    <el-table
      :data="tableData"
      style="width: 100%"
      border
      v-loading="loading"
      height="640px"
    >
      <template v-for="(item, index) in tableConfig">
        <el-table-column :key="index" :label="item.label" :prop="item.prop">
          <template slot-scope="scope">
            <div v-if="item.prop === 'Time'">
              <span>{{ formartDate(scope.row.StartTime, "day") }}</span>
              <span> 至 </span>
              <span>{{ formartDate(scope.row.EndTime, "day") }}</span>
            </div>
            <el-tag
              v-else-if="item.prop === 'ImplementStatus'"
              :type="scope.row[item.prop] | statusFilter"
              >{{ scope.row["ImplementStatus_V"] }}</el-tag
            >
            <div v-else-if="item.prop === 'Evaluate'">
              <el-rate v-model="scope.row.Evaluate" disabled> </el-rate>
            </div>
            <span v-else>{{ scope.row[item.prop] }}</span>
          </template>
        </el-table-column>
      </template>
      <el-table-column label="操作" width="200px">
        <template slot-scope="scoped">
          <el-button
            type="text"
            icon="el-icon-view"
            class="btn-color"
            @click="handleView(scoped.row.Id)"
            >查看</el-button
          >
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import { getPageList } from "@/api/internshipEvaluate";
import { queryApplyJobCatelog } from "@/api/jobApply";
import contentTools from "@/mixins/content-tools";
import { StaffTypeEnum } from "@/utils/constant";
import { mapGetters } from "vuex";
export default {
  filters: {
    statusFilter(status) {
      const statusMap = {
        "-1": "danger", //被驳回
        "-2": "info", //失效
        0: "success", //正常
        1: "warning", //实习中
        10: "", //实习结束
        11: "" // 归档
      };
      if (statusMap[status]) {
        return statusMap[status];
      } else {
        return "";
      }
    }
  },
  computed: {
    ...mapGetters(["employee"]),
    RoleType() {
      const { matched } = this.$route;
      if (matched.some(({ path }) => path.includes("enterprise"))) {
        return StaffTypeEnum.Enterprise;
      } else if (matched.some(({ path }) => path.includes("school"))) {
        return StaffTypeEnum.School;
      }
      return undefined;
    }
  },
  mixins: [contentTools],
  data() {
    return {
      loading: false,
      queryParams: {
        PageSize: 999,
        PageIndex: 1,
        Type: 1
      },
      tableData: [],
      tableConfig: [
        {
          label: "学生姓名",
          prop: "StudentName"
        },
        {
          label: "公司名称",
          prop: "EnterpriseName"
        },
        {
          label: "岗位名称",
          prop: "JobName"
        },
        {
          label: "实习时间",
          prop: "Time"
        },
        {
          label: "实习状态",
          prop: "ImplementStatus"
        },
        {
          label: "学生打分",
          prop: "Evaluate"
        }
      ],
      jobList: [],
      enterpriseList: []
    };
  },
  created() {
    this.initialize();
  },
  methods: {
    async initialize() {
      try {
        this.loading = true;
        if (this.RoleType === StaffTypeEnum.School) {
          let temp = this.employee.find(el => el.StaffType === this.RoleType);
          this.queryParams.SchoolId = temp.Employer.Id;
        } else if (this.RoleType === StaffTypeEnum.Enterprise) {
          let temp = this.employee.find(el => el.StaffType === this.RoleType);
          this.queryParams.EnterpriseId = temp.Employer.Id;
        } else {
          return null;
        }
        await this.getTableData();
        await this.getJobList();
      } catch (err) {
        console.log("err", err);
      } finally {
        this.loading = false;
      }
    },
    async getTableData() {
      const res = await getPageList(this.queryParams);
      if (res.IsSuccess) {
        this.tableData = res.Result.Data;
      }
    },
    async getJobList() {
      const res = await queryApplyJobCatelog({
        schoolId: this.queryParams.SchoolId
      });
      if (res.IsSuccess) {
        this.jobList = res.Result;
      }
    },
    handleView(id) {
      this.$router.push(`/school/practice/evaluate/view?id=${id}`);
    },
    handleEdit(id) {
      this.$router.push(`/school/practice/evaluate/edit?id=${id}`);
    }
  }
};
</script>

<style lang="scss" scoped>
.header-box {
  display: flex;
  justify-content: space-between;
}
.table {
  overflow-x: auto;
}
</style>
